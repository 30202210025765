import api from 'core/util/api';
import { action } from 'mobx';

class SsoLookupStore {
  #ssoLookups = {};

  @action
  async lookup({ email, recaptcha }) {
    if (!this.#ssoLookups[email]) {
      this.#ssoLookups[email] = api
        .post('/api/ui/ssolookup', { showErrorToast: false, data: { email, recaptcha } })
        .catch(() => {
          // hide error but do not store error results (as these may be caused by bad recaptcha or server issue)
          delete this.#ssoLookups[email];
          return { companies: [] };
        });
    }
    return this.#ssoLookups[email];
  }
}

export const SsoLookups = new SsoLookupStore();
